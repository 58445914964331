/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
} from "@mui/material";

function Timer() {
  const [workTime, setWorkTime] = useState(25); // Default work time (25 minutes)
  const [breakTime, setBreakTime] = useState(5); // Default short break time (5 minutes)
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(workTime * 60); // Time in seconds
  const [isWorkSession, setIsWorkSession] = useState(true);

  useEffect(() => {
    let timer;

    if (isTimerActive) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isTimerActive]);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (isWorkSession) {
        setIsWorkSession(false); // Switch to break
        setTimeLeft(breakTime * 60);
      } else {
        setIsWorkSession(true); // Switch back to work
        setTimeLeft(workTime * 60);
      }
    }
  }, [timeLeft, isWorkSession, breakTime, workTime]);

  const handleStartStop = () => {
    setIsTimerActive((prev) => !prev);
  };

  const handleReset = () => {
    setIsTimerActive(false);
    setTimeLeft(workTime * 60); // Reset to work time
    setIsWorkSession(true);
  };

  const handleFocusTimer = () => {
    setWorkTime(25); // Focus work time
    setBreakTime(5); // Short break time
    setIsWorkSession(true); // Start with work session
    setTimeLeft(25 * 60); // Set to 25 minutes in seconds
  };

  const handleShortBreak = () => {
    setWorkTime(25); // Focus work time
    setBreakTime(5); // Short break time
    setIsWorkSession(false); // Start with break session
    setTimeLeft(5 * 60); // Set to 5 minutes in seconds
  };

  const handleLongBreak = () => {
    setWorkTime(25); // Focus work time
    setBreakTime(30); // Long break time
    setIsWorkSession(false); // Start with break session
    setTimeLeft(30 * 60); // Set to 15 minutes in seconds
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        p: 2,
        backgroundColor: "#f1f1f1",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url('https://images.pexels.com/photos/326055/pexels-photo-326055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')", // Set your wallpaper image URL here
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Card
        sx={{
          borderRadius: 2,
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.1)",
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Light background overlay for contrast
          p: 4,
          textAlign: "center",
          maxWidth: 400,
          width: "100%",
        }}
      >
        <CardContent>
          <Typography variant="h5" sx={{ fontSize: "2rem", fontWeight: 600, color: "#333", mb: 2 }}>
            {isWorkSession ? "Focus Time" : "Break Time"}
          </Typography>

          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              color: "#FF6347", // Highlighted color for time
              mb: 2,
              padding: "20px 30px",
              borderRadius: "15px",
              backgroundColor: "rgba(255, 99, 71, 0.2)", // Light red background for time
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
              fontSize: "3rem",
              letterSpacing: "2px",
            }}
          >
            {formatTime(timeLeft)}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2 }}>
            <Button
              variant="contained"
              sx={{
                fontSize: "1.2rem",
                px: 4,
                py: 1.5,
                borderRadius: "30px",
                backgroundColor: isTimerActive ? "#FF6347" : "#4CAF50",
                color: "#fff",
                "&:hover": {
                  backgroundColor: isTimerActive ? "#ff503f" : "#45a049",
                },
                transition: "all 0.3s ease",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleStartStop}
            >
              {isTimerActive ? "Pause" : "Start"}
            </Button>

            <Button
              variant="outlined"
              sx={{
                fontSize: "1.2rem",
                px: 4,
                py: 1.5,
                borderRadius: "30px",
                color: "#333",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <Button
              variant="outlined"
              sx={{
                fontSize: "1.1rem",
                px: 3,
                py: 1.5,
                borderRadius: "30px",
                backgroundColor: "#1E88E5",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#1565C0",
                },
                transition: "all 0.3s ease",
              }}
              onClick={handleFocusTimer}
            >
              Focus Timer
            </Button>

            <Button
              variant="outlined"
              sx={{
                fontSize: "1.1rem",
                px: 3,
                py: 1.5,
                borderRadius: "30px",
                backgroundColor: "#FF9800",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#FB8C00",
                },
                transition: "all 0.3s ease",
              }}
              onClick={handleShortBreak}
            >
              Short Break
            </Button>

            <Button
              variant="outlined"
              sx={{
                fontSize: "1.1rem",
                px: 3,
                py: 1.5,
                borderRadius: "30px",
                backgroundColor: "#FF5722",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#E64A19",
                },
                transition: "all 0.3s ease",
              }}
              onClick={handleLongBreak}
            >
              Long Break
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Timer;
