import { Box, Card, 
  // CardContent, 
  Typography, Stepper, Step, StepLabel, 
  Modal,
  IconButton, useMediaQuery} from '@mui/material';
import { EmojiObjects, AddCircle, Repeat } from '@mui/icons-material';
import add from '../app/assets/images/add.png'
import recall from '../app/assets/images/recall.png'
import mastery from '../app/assets/images/mastery.png'
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@mui/system";

const steps = [
  {
    imageUrl: 'https://images.pexels.com/photos/301920/pexels-photo-301920.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    icon: <EmojiObjects style={{ color: '#fbc02d' }} />, // Lightbulb icon for "Study"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Study Topic</span>,
    description: <span style={{ color: '#555' }}>Study the topic until you achieve <strong>complete memorization</strong>.</span>,
  },
  {
    imageUrl: add,
    icon: <AddCircle style={{ color: '#4caf50' }} />, // Add icon for "Add Topic"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Add to App</span>,
    description: <span style={{ color: '#555' }}>Incorporate the topic into the app to <strong>monitor your progress</strong>.</span>,
  },
  {
    imageUrl: recall,
    icon: <Repeat style={{ color: '#2196f3' }} />, // Repeat icon for "Revise"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Initial Revision</span>,
    description: (
      <div style={{ color: '#555' }}>
        <p><strong>1.</strong> When your memory shows <strong>40%</strong>, try to recall what you learned last.</p>
        <p><strong>2.</strong> Review the topic until you can <strong>remember everything</strong>.</p>
        <p><strong>3.</strong> <strong>Update the topic</strong> in the app once fully memorized.</p>
      </div>
    ),
  },
  {
    imageUrl: 'https://pix4free.org/assets/library/2021-06-16/originals/repeat.jpg',
    icon: <Repeat style={{ color: '#2196f3' }} />, // Repeat icon for "Subsequent Revision"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Subsequent Revision</span>,
    description: (
      <div style={{ color: '#555' }}>
        <p><strong>1.</strong> After some time, check if your memory drops back to <strong>40%</strong>.</p>
        <p><strong>2.</strong> Review the material again until you reach <strong>complete memorization</strong>.</p>
        <p><strong>3.</strong> <strong>Update the topic</strong> in the app once mastered.</p>
      </div>
    ),
  },
  {
    imageUrl: mastery,
    icon: <Repeat style={{ color: '#2196f3' }} />, // Repeat icon for "Reinforce Learning"
    title: <span style={{ color: '#1976d2', fontWeight: 'bold' }}>Reinforce Learning</span>,
    description: (
      <div style={{ color: '#555' }}>
        <p><strong>1.</strong> Continue to <strong>repeat the revision process regularly</strong>.</p>
        <p><strong>2.</strong> This will help <strong>reinforce your understanding</strong> and retention.</p>
      </div>
    ),
  },
];



// Motivational message at the end of the steps
const motivationMessage = "Unlock your true potential with consistent practice! Stay focused and let the app guide you to mastery.";

const UsageGuide = () => {

  const [openIndex, setOpenIndex] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleOpen = (index) => {
    setOpenIndex(index);
  };

  const handleClose = () => {
    setOpenIndex(null);
  };

  return (
    <Box 
    sx={{ 
      minWidth: isMobile ? '100%' : 600, 
      mx: 'auto', 
      mt: 4,
      px: isMobile ? 2 : 0 // Add padding on mobile
    }}
  >
    <Typography 
      variant={isMobile ? "h5" : "h4"} 
      align={isMobile ? "center" : "left"} 
      gutterBottom
    >
      How to Use the App
    </Typography>

    <Stepper 
      orientation={isMobile ? "vertical" : "horizontal"} 
      sx={{ mt: 2 }}
    >
      {steps.map((step, index) => (
        <Step key={index} active completed>
          <StepLabel icon={step.icon}>
            <Card 
              variant="outlined" 
              sx={{ 
                mt: 2, 
                display: 'block', 
                alignItems: 'center', 
                p: 2, 
                textAlign: isMobile ? 'center' : 'left'
              }}
            >
              <Box 
                sx={{ 
                  width: isMobile ? '100%' : 200, 
                  height: isMobile ? 'auto' : 200, 
                  mr: 0, 
                  mb: 2, 
                  cursor: 'pointer' 
                }} 
                onClick={() => handleOpen(index)}
              >
                <img 
                  src={step.imageUrl} 
                  alt={step.title} 
                  style={{ 
                    width: '100%', 
                    height: isMobile ? 'auto' : '100%', 
                    borderRadius: '10%' 
                  }} 
                />
              </Box>
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  {step.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {step.description}
                </Typography>
              </Box>
            </Card>
          </StepLabel>

          {/* Modal for Full Image */}
          <Modal
            open={openIndex === index}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box 
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: isMobile ? 1 : 2,
                outline: 'none',
                maxWidth: '90vw',
                maxHeight: '90vh'
              }}
            >
              <IconButton 
                onClick={handleClose} 
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: 'grey.700'
                }}
              >
                <CloseIcon />
              </IconButton>
              <img 
                src={step.imageUrl} 
                alt={step.title} 
                style={{ 
                  maxWidth: '100%', 
                  maxHeight: '80vh', 
                  display: 'block', 
                  margin: '0 auto' 
                }} 
              />
            </Box>
          </Modal>
        </Step>
      ))}
    </Stepper>
  </Box>
  );
};

export default UsageGuide;
