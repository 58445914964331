import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Toast from "../../../component/ToastService";
import { StatusType } from "../../../constants/Status";
import { clear_logged_data } from "../../../service/Configuration";
import logocrop from "../assets/images/logo-crop.png";
import { logout } from "../services/AppService";
import { BottomNavigation, BottomNavigationAction, Paper, useMediaQuery } from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import AddTaskIcon from '@mui/icons-material/AddTask';
// import NotificationsIcon from '@mui/icons-material/Notifications'; 
import ListIcon from '@mui/icons-material/List';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMenuOpen = Boolean(anchorEl);

  const menuId = "profile-menu";
  const iconColor = "#007acc";

  const sidebarUrls = [
    { text: "Dashboard", url: "/app/dashboard" },
    { text: "Topics", url: "/app/topics" },
    { text: "Recall", url: "/app/recalls" },
    { text: "Memorized", url: "/app/memorized" },
    { text: "Timer", url: "/app/timer" },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const logoutUser = async () => {
    try {
      const response = await logout();
      if (response.ok) {
        const data = await response.json();
        
      } else {
        if (response.status === 401) {
          console.error("Unauthorized access - logging out user");
          navigate("/auth/logout");
        }
        console.error("Failed to fetch dashboard data");
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const handleMenuClose = (link) => {
    if (link === "profile") {
      navigate("/app/profile");
    }
    if (link === "changepassword") {
      navigate("/app/change-password");
    }
    if (link === "logout") {      
      logoutUser();
      clear_logged_data();
      Toast(StatusType.SUCCESS, "Logout successfully");
      navigate("/auth/login");
    }
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {
        !isMobile ? 
          <>
            <AppBar position="fixed" open={open}>
              <Toolbar>
                {!open && (
                  <Typography variant="h6" noWrap component="div">
                    <img
                      src={logocrop}
                      className="ms-0"
                      width="60px"
                      alt="Rise to Mastery"
                      style={{
                        transform: "translateX(-12px)",
                        filter: "invert(100%)",
                      }}
                    />
                  </Typography>
                )}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <div className="w-100 d-flex justify-content-end">
                  <IconButton
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    color="inherit"
                  >
                    <AccountCircleIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    id={menuId}
                    keepMounted
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    className="mt-5 ms-4"
                    PaperProps={{
                      style: {
                        width: "280px",
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        handleMenuClose("profile");
                      }}
                    >
                      <AccountCircleIcon className="me-3" /> My Profile
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleMenuClose("changepassword");
                      }}
                    >
                      <LockOutlinedIcon className="me-3" />
                      Change Password
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleMenuClose("logout");
                      }}
                    >
                      <ExitToAppIcon className="me-3" /> Logout
                    </MenuItem>
                  </Menu>
                </div>
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <Typography variant="h6" noWrap component="div">
                  <img
                    src="/static/media/Rapid learn-logos_transparent1.a8c11d1285af0341ce4c.png"
                    width="190px"
                    alt="Rise to Mastery"
                  />
                </Typography>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {sidebarUrls.map((data) => (
                  <ListItem
                    key={data.text}
                    disablePadding
                    sx={{
                      display: "block",
                      "&:hover": {
                        bgcolor: "#fjf0f0",
                      },
                      ...(location.pathname === data.url && {
                        bgcolor: "#007acc",
                        "& .MuiListItemIcon-root, & .MuiTypography-root": {
                          color: "#fff",
                        },
                      }),
                    }}
                  >
                    <Link
                      to={data.url}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "center",
                          px: 5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: "center",
                            color: iconColor,
                          }}
                        >
                          {data.text === "Dashboard" ? (
                            <DashboardIcon />
                          ) : data.text === "Topics" ? (
                            <ListAltOutlinedIcon />
                          ) : data.text === "Recall" ? (
                            <AssignmentReturnOutlinedIcon />
                          ) : data.text === "Memorized" ? (
                            <AutoAwesomeIcon />
                          ) : (
                            <LeaderboardOutlinedIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={data.text} />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                ))}
              </List>
              <Divider />
            </Drawer> 
          </>
        :
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: (theme) => theme.zIndex.drawer + 10 }} elevation={3}>
            <BottomNavigation
              showLabels
              value={location.pathname}
              onChange={(event, newValue) => {               
              }}
              sx={{               
                '& .MuiButtonBase-root:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)', 
                }
              }}
            >
              <BottomNavigationAction 
                label="Home" 
                icon={<HomeIcon />}  
                onClick={() => navigate('/app/home')} 
                value="/app/home"
              />
              <BottomNavigationAction 
                label="Add Topic" 
                icon={<AddTaskIcon />} 
                onClick={() => navigate('/app/add-topic')} 
                value="/app/add-topic"
              />
              <BottomNavigationAction 
                label="Topics" 
                icon={<ListIcon />}  
                onClick={() => navigate('/app/topics')} 
                value="/app/topics"
              />
              <BottomNavigationAction 
                label="You" 
                icon={<AccountCircleIcon />} 
                onClick={() => navigate('/app/profile')} 
                value="/app/profile"
              />
            </BottomNavigation>
          </Paper>
      }
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#f4f6f9",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {
        !isMobile ?
        <DrawerHeader className="mt-0" />
        :
        <></>
        }
        <Outlet />
      </Box>
    </Box>

  );
}
